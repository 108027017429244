<template>
  <div class="topicCommunicateView">
    <div class="topicCommunicateView_left">
      <div class="topicCommunicateView_head">
        <userInfo
          :userBasicVO="data.userBasicVO"
          :user-name="isAnonymous ? '匿名' : data.userBasicVO.username"
          :iconUrl="isAnonymous ? '' : data.userBasicVO.avatar"
          :time="data.createTime"
          time-stamp-format-template="YYYY-MM-DD HH:mm"
          :admin="false"
        />
        <div v-if="isShowReply" class="reply" @click.stop="handleReply">回复</div>
      </div>
      <div class="topicCommunicateView_content" @click="goDetails">
        <div class="content-container">
          <p
            class="topicCommunicateView_content_text"
            v-html="handleContent(getContentText)"
          ></p>
        </div>
        <overview
          :userName="data.module.username"
          :content="data.module.title"
          :imgUrl="data.module.imageUrl"
          v-if="!hideOverview"
        />
      </div>
    </div>
    <van-icon
      name="arrow"
      v-if="hideOverview"
      color="#595959"
      size="18"
      @click="goDetails"
    />
  </div>
</template>

<script>
import userInfo from './components/userInfo'
import overview from './components/overview'
import {handleContent} from '@/utils/utils'

export default {
  props: {
    data: Object,
    type: String,
    isShowReply: {
      type: Boolean,
      default: false
    },
    hideOverview: Boolean
  },
  components: {
    userInfo,
    overview
  },
  computed: {
    getContentText () {
      const {type, data} = this
      let typeText = '赞了你的评论：'
      if(data.fromEntityType === 1){
        typeText = '赞了你的帖子：'
      }
      if (type === 'comment') {
        return data.content || ''
      } else if (type === 'about') {
        return  data.content || ''
      } else {
        // 点赞
        return (typeText + data.content) || ''
      }
    },
    isAnonymous () {
      const {
        data: {isAnonymous}
      } = this
      return parseInt(isAnonymous) === 1
    },
    isToAnonymous () {
      const {
        data: {isAnonymous}
      } = this
      return parseInt(isAnonymous) === 1
    }
  },
  methods: {
    handleContent,
    goDetails () {
      this.$router.push({
        name: 'topicDetail',
        query: {id: this.data.module.articleId}
      })
    },
    handleReply () {
      this.$emit('reply', this.data)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .topicCommunicateView_content_text {
    margin: 6px 0;
    padding: 0 !important;

    a {
      margin: 0 2px;
      color: #fe5800;
    }
  }
}

.content-container {
  display: flex;
  align-items: center;
}

.reply {
  display: inline-block;
  color: #fe5800;
  font-size: 14px;
}

.topicCommunicateView {
  background-color: white;
  padding: 10px 20px;
  margin: 10px 0;
  display: flex;
  align-items: center;

  &_left {
    flex: 1;
  }

  &_head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .van-button {
      height: 24px;
      width: 56px;
      font-size: 12px;
    }
  }

  &_content {
    &_text {
      padding: 15px 0;
      @include mainText;
      @include ellipsis(3);
    }
  }
}
</style>
