<template>
  <div class="overviewContent">
    <div class="overview" v-if="isVote!==1">
      <div class="overview_img" v-if="imgUrl.length > 0">
        <img :src="imgUrl">
      </div>
      <div class="overview_text">
        <p class="title">@{{ userName }}</p>
        <p class="content">{{ content }}</p>
      </div>
    </div>
    <div v-else class="voteContent">
      <p class="title">@{{ ADMIN_NAME }}</p>
      <vote-content hideVoteInfo :voteList='voteList' :pattern='pattern' :voteCount='voteCount' :title='title'
                    :isMeVoted='voteFlag' :voteStartTime='voteStartTime' :voteEndTime='voteEndTime'
                    @click="handleVoteClick" @overviewVoteClick="handleVoteClick"/>
    </div>
  </div>
</template>

<script>
import voteContent from '../components/voteContent'
import {ADMIN_NAME} from "@/config/config";

export default {
  components: {
    voteContent
  },
  props: {
    imgUrl: String,
    title: String,
    content: String,
    userName: String,
    isVote: Number,
    pattern: Number,
    voteList: Array,
    voteCount: Number,
    voteFlag: Boolean,
    voteStartTime: String,
    voteEndTime: String,
  },
  data() {
    return {
      ADMIN_NAME
    }
  },
  methods: {
    handleVoteClick() {
      this.$emit('voteClick')
    }
  }

}
</script>

<style lang="scss" scoped>
.overviewContent {
  background-color: $whiteGray;
  border-radius: 6px;

  .title {
    color: $titleColor;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    @include ellipsis(1);
    margin-bottom: 5px
  }

  .overview {
    display: flex;
    height: 80px;
    border-radius: 6px;
    overflow: hidden;

    &_img {
      width: 80px;
      height: 80px;
      flex-shrink: 0;
      position: relative;
      overflow: hidden;
      img{
        width: 80px;
        height: 80px;
        object-fit: cover;
      }
    }

    &_text {
      flex: 1;
      box-sizing: border-box;
      padding: 10px 10px;
      background-color: $whiteGray;
      overflow: hidden;

      .content {
        @include remark;
        @include ellipsis(2);
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis; /* 加省略号 */
        -webkit-line-clamp: 2;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    }
  }
  .voteContent {
    .title {
      padding: 10px 10px 0;
    }
  }
}
</style>
