<template>
  <div class="avatar-common-box" @click="handleAvatarClick">
    <div v-if="isNewFlag" class="dot-new"></div>
    <img v-if="userInfo" class="avatar-common" :src="userInfo.avatar" />
    <img v-if="userInfo && userInfo.avatarFrameUrl" class="avatar-frame-common" :src="userInfo.avatarFrameUrl" />
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    userInfo: {
      type: Object,
      default: () => {
      }
    },
    // 是否有获得新的头像框
    isNewFlag: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleAvatarClick() {
      this.$emit('avatarClick')
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-common-box {
  position: relative;

  .dot-new{
    position: absolute;
    top: 6px;
    right: 6px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #EC0909;
    z-index: 9;
  }

  .avatar-common {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 71%;
    height: 71%;
    margin: auto;
    border-radius: 50%;
    object-fit: cover;
  }

  .avatar-frame-common {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
