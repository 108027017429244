<template>
  <div class="aboutMeView">
    <pageHead title="点赞"/>
    <div v-infinite-scroll="loadMore" class="aboutMeView_content">
      <topicCommunicate v-for="(item) in records" :key="item.formEntityType" :data="item" type='like'  class="likeView_content_item"/>
      <no-more v-if="!params.hasNextPage" style="margin-top:14px" />
    </div>
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import topicCommunicate from '@/components/topicItem/communicate'
import {listThumbsUpMessage} from "@/api/message";
import NoMore from '@/components/uitls/NoMore'

export default {
  components: {
    pageHead,
    topicCommunicate,
    NoMore
  },
  data(){
    return {
      params:{
        type: 1,
        pageNum:1,
        pageSize: 10,
        hasNextPage: true
      },
      records:[]
    }
  },
  methods:{
    loadMore() {
      this.loadList();
    },
    loadList () {
      let listQueryParams = this.params;
      if (listQueryParams.hasNextPage) {
        listThumbsUpMessage(this.params).then(res => {
          listQueryParams.hasNextPage = res.current < res.pages
          listQueryParams.pageNum = listQueryParams.pageNum + 1
          let records = this.records;
          res.records.forEach(item =>{
            let count = records.filter(innerItem => innerItem.messageId === item.messageId)
            if(count.length === 0){
              records.push(item)
            }
          })
          this.records = records
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.aboutMeView {
  height: 100%;
  display: flex;
  flex-direction: column;

  &_content {
    flex: 1;
    overflow-y: auto;

    &_item {
      margin-top: 10px;
    }
  }
}

</style>
